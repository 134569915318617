import {handleErrorWithSentry} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SVELTE_KIT_APP_SENTRY_DSN,
    tracesSampleRate: 0.2,
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
